import React, {
  useCallback
} from 'react'

import withObservables from '@nozbe/with-observables'
import PropTypes from 'prop-types'

import isNaN from 'lodash/isNaN'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import toNumber from 'lodash/toNumber'

import Grid from '@material-ui/core/Grid'

import { animalService } from '@smartcoop/database/services/animalService'
import { database } from '@smartcoop/database/web-database'
import registerObservationsSchema from '@smartcoop/forms/schemas/dairyFarm/registerObservations.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { colors } from '@smartcoop/styles'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputText from '@smartcoop/web-components/InputText'

import {
  Container,
  ButtonsContainer,
  FormContainer,
  Item
} from './styles'

const RegisterObservationsForm = (props) => {
  const { defaultValues, onSubmit, onCancel, loading, animals, formRef } = props


  const t = useT()

  const handleSubmit = useCallback(
    (data) => {
      onSubmit({
        ...defaultValues,
        ...data
      })
    },
    [defaultValues, onSubmit]
  )

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ registerObservationsSchema }
        onSubmit={ handleSubmit }
      >
        <FormContainer>
          <Grid container style={ { justifyContent: 'space-between' } }>
            <Item>
              <InputSelect
                label={ t('earrings') }
                name="animalsIds"
                options={ orderBy(map(animals, item => ({ label: item?.earringCode, value: item?.animalId })), item => isNaN(toNumber(item?.label)) ? item?.label : toNumber(item?.label)) }
                multiple
                defaultValue={ [defaultValues?.animal?.id] }
                disabled={ !!defaultValues?.id }
                checkBoxSelectAll
                order={ false }
              />
            </Item>
            <Item>
              <InputText
                label={ t('observation', { howMany: 1 }) }
                name="observation"
                defaultValue={ defaultValues.observation }
                fullWidth
                multiline
                rows={ 6 }
              />
            </Item>
          </Grid>
        </FormContainer>
        <ButtonsContainer>
          <Button
            id="web-cancel-form-button"
            onClick={ onCancel }
            color={ colors.white }
            disabled={ loading }
            style={ { marginRight: 10 } }
          >
            <I18n>cancel</I18n>
          </Button>
          <Button
            id="web-save-form-button"
            onClick={ () => formRef.current.submit() }
            disabled={ loading }
            style={ { marginLeft: 10 } }
          >
            <I18n>save</I18n>
          </Button>
        </ButtonsContainer>
      </Form>
    </Container>
  )
}

RegisterObservationsForm.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  animals: PropTypes.array,
  defaultValues: PropTypes.object.isRequired,
  formRef: PropTypes.object.isRequired
}

RegisterObservationsForm.defaultProps = {
  loading: false,
  animals: [],
  onSubmit: () => {},
  onCancel: () => {}
}

const enhance = withObservables([], () => ({
  animals: animalService(database).observeAnimals()
}))

const EnhancedRegisterObservationsForm = enhance(RegisterObservationsForm)

export default EnhancedRegisterObservationsForm