import React, { useCallback, useState, useMemo, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { AnimalActions } from '@smartcoop/stores/animal'
import { selectCurrentAnimal } from '@smartcoop/stores/animal/selectorAnimal'
import { AnimalPregnancyDiagnosticsActions } from '@smartcoop/stores/animalPregnancyDiagnostics'
import Modal from '@smartcoop/web-components/Modal'
import RegisterDiagnosisActionsForm from '@smartcoop/web-containers/forms/digitalProperty/dairyFarm/RegisterDiagnosisActionsForm'
import LoadingModal from '@smartcoop/web-containers/modals/LoadingModal'

import useStyles from './styles'

const RegisterDiagnosisActionsModal = (props) => {
  const { id, open, onSubmit, handleClose, data: propData, animalId } = props

  const t = useT()
  const formRef = useRef(null)
  const classes = useStyles()
  const snackbar = useSnackbar()
  const dispatch = useCallback(useDispatch(), [])
  const { createDialog, removeDialog } = useDialog()

  const currentAnimal = useSelector(selectCurrentAnimal)

  const [isLoading, setIsLoading] = useState(false)

  const isEditing = useMemo(() => !isEmpty(propData?.id), [propData])
  const defaultValues = useMemo(() => {
    if (isEditing) {
      return {
        earring: propData.animal?.earringCode,
        animalId: animalId || propData.animal?.id,
        ...propData
      }
    }
    return {
      earring: currentAnimal?.earringCode,
      animalId: animalId || currentAnimal?.id,
      ...propData
    }
  }, [animalId, currentAnimal.earringCode, currentAnimal.id, isEditing, propData])

  const closeModal = useCallback(() => {
    setIsLoading(false)
    handleClose()
  }, [handleClose])

  const onSuccess = useCallback(() => {
    dispatch(AnimalActions.loadCurrentAnimal())
    snackbar.success(
      t(`your {this} was ${ isEditing ? 'edited' : 'registered' }`, {
        howMany: 1,
        gender: 'male',
        this: t('diagnosis', { howMany: 1 })
      })
    )
    onSubmit()
    closeModal()
  }, [closeModal, dispatch, isEditing, onSubmit, snackbar, t])

  const handleSubmit = useCallback(
    (data) => {
      setIsLoading(true)
      dispatch(
        AnimalPregnancyDiagnosticsActions.saveOfflinePregnancyDiagnostic(
          {
            ...data
          },
          onSuccess,
          () => {
            setIsLoading(false)
          },
          true
        )
      )
    },
    [dispatch, onSuccess]
  )

  useEffect(() => {
    if(isLoading) {
      createDialog({
        id: 'loading',
        Component: LoadingModal
      })
    } else {
      removeDialog({ id: 'loading' })
    }
  },[createDialog, isLoading, removeDialog])
  return (
    <Modal
      id={ id }
      open={ open }
      title={
        <I18n params={ { this: t('diagnosis', { howMany: 1 }) } }>
          {'register {this}'}
        </I18n>
      }
      disableFullScreen
      disableEsc
      classes={ { paper: classes.modalBackground } }
      contentContainerStyle={ { minWidth: 300 } }
      headerProps={ {
        titleClass: classes.title,
        disableTypography: true
      } }
      maxWidth="sm"
      fullWidth
    >
      <RegisterDiagnosisActionsForm
        formRef={ formRef }
        defaultValues={ defaultValues }
        onSubmit={ handleSubmit }
        onCancel={ closeModal }
      />
    </Modal>
  )
}

RegisterDiagnosisActionsModal.propTypes = {
  id: PropTypes.string.isRequired,
  animalId: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  onSubmit: PropTypes.func,
  data: PropTypes.object
}

RegisterDiagnosisActionsModal.defaultProps = {
  animalId: null,
  handleClose: () => {},
  onSubmit: () => {},
  data: {}
}

export default RegisterDiagnosisActionsModal
