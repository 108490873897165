import React, { useCallback , useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import withObservables from '@nozbe/with-observables'
import moment from 'moment/moment'
import PropTypes from 'prop-types'

import filter from 'lodash/filter'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import isNaN from 'lodash/isNaN'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import toNumber from 'lodash/toNumber'

import { animalService } from '@smartcoop/database/services/animalService'
import { database } from '@smartcoop/database/web-database'
import { useDialog } from '@smartcoop/dialog'
import medicationControlSchema from '@smartcoop/forms/schemas/dairyFarm/medicationControl.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { magnifier } from '@smartcoop/icons'
import { getAnimalsDiseases } from '@smartcoop/services/apis/smartcoopApi/resources/herdsManagement'
import { getLots } from '@smartcoop/services/apis/smartcoopApi/resources/lot'
import { getProducts } from '@smartcoop/services/apis/smartcoopApi/resources/product'
import { getProductGroups } from '@smartcoop/services/apis/smartcoopApi/resources/productGroup'
import { selectCurrentPropertyId } from '@smartcoop/stores/property/selectorProperty'
import { selectUserCanWrite } from '@smartcoop/stores/user/selectorUser'
import { colors } from '@smartcoop/styles'
import { momentBackDateFormat } from '@smartcoop/utils/dates'
import Button from '@smartcoop/web-components/Button'
import CheckboxButton from '@smartcoop/web-components/CheckboxGroup/CheckboxButton'
import Form from '@smartcoop/web-components/Form'
import Icon from '@smartcoop/web-components/Icon'
import InputDate from '@smartcoop/web-components/InputDate'
import InputFloat from '@smartcoop/web-components/InputFloat'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputText from '@smartcoop/web-components/InputText'
import InputUnit from '@smartcoop/web-components/InputUnit'
import CheckboxesModal from '@smartcoop/web-containers/modals/CheckboxesModal'

import {
  Container,
  Row,
  ButtonsContainer
} from './styles'

const MedicationControlForm = (props) => {
  const {
    loading,
    onSubmit,
    setIsLoading,
    onCancel,
    defaultValues,
    animalId,
    animalsList,
    formRef
  } = props

  const t = useT()
  const { createDialog } = useDialog()

  const userWrite = useSelector(selectUserCanWrite)
  const propertyId = useSelector(selectCurrentPropertyId)

  const [lotOptions, setLotOptions] = useState([])
  const [productOptions, setProductOptions] = useState([])
  const [diseasesOptions, setDiseasesOptions] = useState([])
  const [animals, setAnimals] = useState([animalId || defaultValues?.animal?.id])
  const [carencyTime, setCarencyTime] = useState(defaultValues?.carencyTime || '0')
  const [medicationStartDate, setMedicationStartDate] = useState(defaultValues?.medicationStartDate || '')
  const [treatmentTime, setTreatmentTime] = useState(defaultValues?.treatmentTime)
  const [type, setType] = useState(defaultValues?.type)
  const [checkbox, setCheckbox] = useState(false)
  const [manualMedicationEndDate, setManualMedicationEndDate] = useState(defaultValues?.medicationEndDate)

  // eslint-disable-next-line no-nested-ternary
  const medicationEndDate = useMemo(() => !isEmpty(medicationStartDate) ? type === 'secagem' ? moment(medicationStartDate, momentBackDateFormat) : moment(medicationStartDate, momentBackDateFormat).add(treatmentTime ? toNumber(treatmentTime) : 0, 'days') : '',[medicationStartDate, treatmentTime, type])

  const carencyLiberationDate = useMemo(() => {
    if(type === 'diagnostico-de-doenca' && !isEmpty(medicationEndDate)) {
      return moment(medicationEndDate, momentBackDateFormat).add(carencyTime ? toNumber(carencyTime) : 0, 'days')
    }
    if(type === 'calendario-de-vacinacao' && !isEmpty(manualMedicationEndDate)) {
      return moment(manualMedicationEndDate, momentBackDateFormat).add(carencyTime ? toNumber(carencyTime) : 0, 'days')
    }
    if(type === 'secagem') {
      return moment(medicationEndDate, momentBackDateFormat).add(carencyTime ? toNumber(carencyTime) : 0, 'days')
    }
    return moment(medicationEndDate, momentBackDateFormat)
  }
  ,[carencyTime, manualMedicationEndDate, medicationEndDate, type])

  const schemaProps = useMemo(() => ({
    realizado: checkbox
  }), [checkbox])

  const handleSubmit = useCallback(
    (data) => {
      const updatedData = {
        ...data,
        registerCode: defaultValues?.registerCode,
        propertyId,
        // eslint-disable-next-line no-nested-ternary
        medicationEndDate: type !== 'calendario-de-vacinacao' ? medicationEndDate : (checkbox ? manualMedicationEndDate : null),
        treatmentTime: toNumber(data?.treatmentTime),
        carencyTime: toNumber(data?.carencyTime),
        ccsPrepartum: toNumber(data?.ccsPrepartum),
        ccsPostpartum: toNumber(data?.ccsPostpartum)
      }
      Object.keys(updatedData).forEach(key => {
        if (updatedData[key] === '') {
          delete updatedData[key]
        }
      })

      onSubmit(updatedData)
    },
    [checkbox, defaultValues.registerCode, manualMedicationEndDate, medicationEndDate, onSubmit, propertyId, type]
  )

  const loadLots = useCallback(async () => {
    const { data: { data } } = await getLots({}, { propertyId })

    setLotOptions(map(data, item => ({
      label: item.name,
      value: item.id
    })))
  },[propertyId])

  const loadDiseases = useCallback(async () => {
    const { data: { data } } = await getAnimalsDiseases({ type, limit: 99999 })

    setDiseasesOptions(map(data, item => ({
      label: item.name,
      value: item.id
    })))
  },[type])

  const loadProducts = useCallback(async () => {
    const { data: { data: productGroups } } = await getProductGroups({ slug: 'produtos-veterinarios' })
    const { data: { data } } = await getProducts({ productGroupId: productGroups[0]?.id, limit: 99999 })

    setProductOptions(map(data, item => ({
      label: item.shortDescription,
      value: item.id
    })))
  },[])

  const onChangeLots = useCallback((lots) => {
    setIsLoading(true)
    const updatedAnimalsFromLots = []
    const func = map(lots, (lot) => {
      const newAnimalsId = map(filter(animalsList, (item) => item?.lotId === lot), (item) => item?.animalId)
      updatedAnimalsFromLots.push(...newAnimalsId)
    })
    Promise.all(func).then(() => {
      const currentAnimals = [...animals]
      const oldAndNotNew = filter(currentAnimals, (item) => !includes(updatedAnimalsFromLots, item))
      const updatedAnimals = [...oldAndNotNew, ...updatedAnimalsFromLots]
      formRef.current.getFieldRef('animalIds').setValue(updatedAnimals)
      setAnimals(updatedAnimals)
    })
    setIsLoading(false)
  },[animals, animalsList, formRef, setIsLoading])


  useEffect(() => {
    loadLots()
    loadProducts()
    loadDiseases()
  },[loadDiseases, loadLots, loadProducts])

  useEffect(() => {
    if(isEmpty(manualMedicationEndDate)) {
      setManualMedicationEndDate(medicationStartDate)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[medicationStartDate])

  const typeOptions = [
    {
      label: 'Calendário de vacinação',
      value: 'calendario-de-vacinacao'
    },
    {
      label: 'Diagnóstico de doença',
      value: 'diagnostico-de-doenca'
    },
    {
      label: 'Secagem',
      value: 'secagem'
    },
    {
      label: 'Hormônios, Estimulantes e Suplementos',
      value: 'hormonios-estimulantes-suplementos'
    },
    {
      label: 'Outras Vacinas Preventivas',
      value: 'outras-vacinas-preventivas'
    }
  ]

  const doseUnitOptions = [
    {
      label: 'ml',
      value: 'ml'
    },
    {
      label: 'mg',
      value: 'mg'
    },
    {
      label: 'g',
      value: 'g'
    }
  ]

  const applicationModeOptions = [
    {
      label: 'Injetável',
      value: 'Injetável'
    },
    {
      label: 'Intramamário',
      value: 'Intramamário'
    },
    {
      label: 'Tópico',
      value: 'Tópico'
    },
    {
      label: 'Oral',
      value: 'Oral'
    }
  ]

  const treatmentInterval = [
    {
      label: 'Dose única',
      value: 'Dose única'
    },
    {
      label: 'Diária',
      value: 'Diária'
    },
    {
      label: '4 Horas',
      value: '4 Horas'
    },
    {
      label: '6 Horas',
      value: '6 Horas'
    },
    {
      label: '8 Horas',
      value: '8 Horas'
    },
    {
      label: '12 Horas',
      value: '12 Horas'
    },
    {
      label: '14 Dias',
      value: '14 Dias'
    },
    {
      label: '21 Dias',
      value: '21 Dias'
    }
  ]

  useEffect(() => {
    if(type === 'calendario-de-vacinacao') {
      formRef.current.setData({ applicationMode: 'Injetável' })
      formRef.current.setData({ treatmentInterval: 'Dose única' })
      formRef.current.setData({ treatmentTime: '0' })
      setTreatmentTime('0')
    }
  },[formRef, type])

  useEffect(() => {
    if(type === 'secagem' && !isEmpty(diseasesOptions)) {
      formRef.current.setData({ animalDiseaseIds: [diseasesOptions[0]?.value] })
      formRef.current.setData({ applicationMode: 'Intramamário' })
      formRef.current.setData({ treatmentInterval: 'Dose única' })
      formRef.current.setData({ treatmentTime: '0' })
    }
  },[type, diseasesOptions, formRef])

  return (
    <Form
      style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
      ref={ formRef }
      schemaConstructor={ medicationControlSchema }
      schemaProps={ schemaProps }
      onSubmit={ handleSubmit }
    >
      <Container>
        <Row>
          <InputSelect
            onChange={ ({ target: { value } }) => setAnimals(value) }
            label={ t('earrings') }
            name="animalIds"
            options={ orderBy(map(animalsList, item => ({ label: item?.earringCode, value: item?.animalId })), item => isNaN(toNumber(item?.label)) ? item?.label : toNumber(item?.label)) }
            multiple
            defaultValue={ [animalId || defaultValues?.animal?.id] }
            checkBoxSelectAll
            order={ false }
          />
          <Button
            id="search-lot-button"
            onClick={ () => createDialog(
              {
                id: 'select-lots-modal',
                Component: CheckboxesModal,
                props: {
                  items: lotOptions,
                  title: t('lot', { howMany: 2 }),
                  onConfirm: (response) => {
                    onChangeLots(response)
                  }
                }
              }
            ) }
            variant="outlined"
            style={ { marginBottom: '15px' } }
            disabled={ isEmpty(animalsList) }
          >
            <Icon
              icon={ magnifier }
              style={ { color: '#000' } }
            />
          </Button>
        </Row>
        <InputSelect
          label={ t('type') }
          name="type"
          options={ typeOptions }
          onChange={ ({ target: { value } }) => setType(value) }
          defaultValue={ defaultValues?.type }
        />
        <InputSelect
          label={ t('reason ') }
          name="animalDiseaseIds"
          options={ diseasesOptions }
          multiple
          defaultValue={ !isEmpty(defaultValues?.animalDiseases) ? map(defaultValues?.animalDiseases, item => item.id) : [] }
        />
        <InputDate
          label={ type === 'calendario-de-vacinacao' ? t('predicted date') : t('treatment start date') }
          name="medicationStartDate"
          onChange={ setMedicationStartDate }
          defaultValue={ defaultValues?.medicationStartDate }
          fullWidth
          pickerProps={ { fullWidth: true } }
        />
        <InputSelect
          label={ t('medicine') }
          name="productId"
          options={ productOptions }
          defaultValue={ defaultValues?.productId }
        />
        <Row>
          <InputFloat
            label="Dose"
            name="dose"
            decimalScale={ 2 }
            style={ { marginRight: '20px' } }
            defaultValue={ defaultValues?.dose }
          />
          <InputSelect
            label={ t('unit') }
            name="doseUnit"
            defaultValue={ defaultValues?.doseUnit }
            options={ doseUnitOptions }
          />
        </Row>
        <InputSelect
          label={ t('application mode') }
          name="applicationMode"
          defaultValue={ defaultValues?.applicationMode }
          options={ applicationModeOptions }
        />
        <InputSelect
          label={ t('treatment interval') }
          name="treatmentInterval"
          defaultValue={ defaultValues?.treatmentInterval }
          options={ treatmentInterval }
        />
        {type === 'calendario-de-vacinacao' ? (
          <CheckboxButton
            label={ `${ t('held') }?` }
            onChange={ () => setCheckbox(!checkbox) }
            checked={ checkbox }
            value={ checkbox }
            style={ { width: '100%' } }
          />
        ) : null}
        <Row>
          <InputUnit
            name="treatmentTime"
            label={ t('treatment time') }
            unit={ t('day', { howMany: 2 }) }
            defaultValue={ defaultValues?.treatmentTime }
            onChange={ ({ target: { value } }) => setTreatmentTime(value) }
            type="integer"
            style={
              {
                opacity: type === 'diagnostico-de-doenca' ? 1 : 0,
                heigth: type === 'diagnostico-de-doenca' ? 'auto' : 0,
                marginRight: '10px'
              }
            }
          />
          <InputUnit
            name="carencyTime"
            onChange={ ({ target: { value } }) => setCarencyTime(value) }
            label={ t('carency time') }
            unit={ t('day', { howMany: 2 }) }
            defaultValue={ defaultValues?.carencyTime || '0' }
            type="integer"
            style={ { marginLeft: '10px' } }
          />
        </Row>
        <Row>
          <InputDate
            detached
            fullWidth
            onChange={ (value) => setManualMedicationEndDate(value) }
            disabled={ type !== 'calendario-de-vacinacao' }
            label={ type === 'calendario-de-vacinacao' ? t('realization date') : t('treatment end date') }
            value={ type !== 'calendario-de-vacinacao' ? medicationEndDate : manualMedicationEndDate }
            style={
              {
                opacity: type === 'calendario-de-vacinacao' && !checkbox ? 0 : 1,
                heigth: type === 'calendario-de-vacinacao' && !checkbox ? 0 : 'auto',
                marginRight: '20px'
              }
            }
          />
          <InputDate
            detached
            fullWidth
            disabled
            label={ t('carency liberation date') }
            value={ carencyLiberationDate }
          />
        </Row>

        <Row style={ { opacity: type === 'secagem' ? 1 : 0, height: type === 'secagem' ? 'auto' : 0 } }>
          <InputUnit
            name="ccsPrepartum"
            label={ t('css before birth') }
            defaultValue={ defaultValues?.ccsPrepartum }
            unit="CSS"
            type="integer"
            style={ { marginRight: '20px' } }
          />
          <InputUnit
            name="ccsPostpartum"
            label={ t('css after birth') }
            defaultValue={ defaultValues?.ccsPostpartum }
            unit="CSS"
            type="integer"
          />
        </Row>
        <InputText
          label={ t('observations') }
          name="observation"
          multiple
          fullWidth
          defaultValue={ defaultValues?.observation }
        />
      </Container>

      <ButtonsContainer>
        <Button
          id="web-cancel-form-button"
          onClick={ onCancel }
          color={ colors.white }
          disabled={ loading }
          style={ { marginLeft: '10px', marginRight: '10px' } }
        >
          <I18n>cancel</I18n>
        </Button>
        <Button
          id="web-save-form-button"
          onClick={ () => formRef.current.submit() }
          disabled={ loading || !userWrite }
          style={ { marginLeft: '10px', marginRight: '10px' } }
        >
          <I18n>save</I18n>
        </Button>
      </ButtonsContainer>
    </Form>
  )
}

MedicationControlForm.propTypes = {
  loading: PropTypes.bool,
  animalId: PropTypes.string,
  onSubmit: PropTypes.func,
  defaultValues: PropTypes.object,
  formRef: PropTypes.object.isRequired,
  setIsLoading: PropTypes.func,
  animalsList: PropTypes.array,
  onCancel: PropTypes.func
}

MedicationControlForm.defaultProps = {
  loading: false,
  animalsList: [],
  animalId: null,
  onSubmit: () => {},
  defaultValues: {},
  onCancel: () => {},
  setIsLoading: () => {}
}

const enhance = withObservables([], () => ({
  animalsList: animalService(database).observeAnimals()
}))

const EnhancedMedicationControlForm = enhance(MedicationControlForm)

export default EnhancedMedicationControlForm

