import React, { useRef, useCallback, useMemo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import I18n, { useT } from '@meta-react/i18n'
import moment from 'moment/moment'

import { useDialog } from '@smartcoop/dialog'
import { useSnackbar } from '@smartcoop/snackbar'
import { AnimalActions } from '@smartcoop/stores/animal'
import { selectCurrentAnimal, selectCurrentAnimalWeight } from '@smartcoop/stores/animal/selectorAnimal'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'
import { selectUserCanWrite } from '@smartcoop/stores/user/selectorUser'
import { momentBackDateFormat, momentFriendlyDateFormat } from '@smartcoop/utils/dates'
import Button from '@smartcoop/web-components/Button'
import DataTable from '@smartcoop/web-components/DataTable'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import RegisterWeightControlModal from '@smartcoop/web-containers/modals/dairyFarm/WeightControlModal'

import { Top } from './styles'

const ListWeightControl = () => {
  const { createDialog } = useDialog()
  const t = useT()
  const tableRef = useRef(null)
  const snackbar = useSnackbar()
  const dispatch = useCallback(useDispatch(), [])

  const currentAnimal = useSelector(selectCurrentAnimal)
  const currentProperty = useSelector(selectCurrentProperty)
  const userWrite = useSelector(selectUserCanWrite)
  const animalWeight = useSelector(selectCurrentAnimalWeight)

  const registerDisabled = useMemo(
    () => !userWrite,[userWrite])

  useEffect(() => {
    dispatch(AnimalActions.loadAnimalWeight({ animalId: currentAnimal?.id, limit: 99999, propertyId: currentProperty?.id }))

    return () => {
      dispatch(AnimalActions.resetAnimalWeight())
    }
  }, [currentAnimal, currentProperty, dispatch])

  const columns = useMemo(
    () => [
      {
        title: t('date', { howMany: 1 }),
        field: 'date',
        render: (row) => moment(row?.date, momentBackDateFormat).format(momentFriendlyDateFormat),
        sorting: true,
        defaultSort: 'desc'
      },
      {
        title: `${ t('weight') } (Kg)`,
        field: 'weight',
        render: row => row?.weight
      },
      {
        title: `${ t('height') } (cm)`,
        field: 'height',
        render: row => row?.height || '-'
      },
      {
        title: t('Days'),
        field: 'days'
      },
      {
        title: `${ t('gain') } (Kg)`,
        field: 'gain'
      },
      {
        title: 'GMD',
        field: 'gmd'
      },
      {
        title: 'GPD',
        field: 'gpd'
      }
    ], [t]
  )


  const openCreateModal = useCallback(
    (_, weightControl = {}) => {
      createDialog({
        id: 'create-fields',
        Component: RegisterWeightControlModal,
        props: {
          onSubmit: () => {
            dispatch(AnimalActions.resetAnimalWeight())
            dispatch(AnimalActions.loadAnimalWeight({ animalId: currentAnimal?.id, limit: 99999 }))
          },
          weightControl,
          animalId: currentAnimal?.id
        }
      })
    },
    [createDialog, currentAnimal, dispatch]
  )

  const handleDelete = useCallback(
    (_, row) => {
      createDialog({
        id: 'confirm-delete-access',
        Component: ConfirmModal,
        props: {
          onConfirm: () => {
            dispatch(
              AnimalActions.deleteAnimalWeight(
                row?.id,
                () => {
                  snackbar.success(
                    t('your {this} was removed', { howmany: 1, gender: 'male', this: t('weight control') })
                  )
                  dispatch(AnimalActions.resetAnimalWeight())
                  dispatch(AnimalActions.loadAnimalWeight({ animalId: currentAnimal?.id, limit: 99999 }))
                }
              )
            )
          },
          textWarning: t('are you sure you want to remove the {this}?', {
            howMany: 1,
            gender: 'male',
            this: t('weight control')
          })
        }
      })
    },
    [createDialog, currentAnimal.id, dispatch, snackbar, t]
  )

  return (
    <>
      <Top>
        {/* <div>
          <FilterButton
            onClick={ openFilterModal }
            isActive={ !values(filters).every(isEmpty) }
          />
        </div> */}
        <div>
          <Button
            id="register-weight-control"
            onClick={ openCreateModal }
            color="secondary"
            disabled={ registerDisabled }
          >
            <I18n>register</I18n>
          </Button>
        </div>
      </Top>
      <div style={ { marginTop: 20, width: 'calc(100% - 30px)' } }>
        <DataTable
          columns={ columns }
          data={ animalWeight }
          tableRef={ tableRef }
          onEditClick={ openCreateModal }
          onDeleteClick={ handleDelete }
          disabled={ !userWrite }
          id="list-insemination-table"
        />
      </div>
    </>
  )
}

export default ListWeightControl
