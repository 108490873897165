import React, { useCallback, useState, useMemo, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import { isEmpty } from 'lodash'

import  I18n, { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { AnimalProtocolsActions } from '@smartcoop/stores/animalProtocol'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'
import Loader from '@smartcoop/web-components/Loader'
import Modal from '@smartcoop/web-components/Modal'
import RegisterProtocolForm from '@smartcoop/web-containers/forms/digitalProperty/dairyFarm/RegisterProtocolForm'

import useStyles from './styles'

const RegisterProtocolModal = (props) => {
  const {
    id,
    open,
    onSubmit,
    handleClose,
    animalId,
    protocol
  } = props

  const formRef = useRef(null)
  const classes = useStyles()
  const snackbar = useSnackbar()
  const dispatch = useCallback(useDispatch(), [])
  const t = useT()
  const currentProperty = useSelector(selectCurrentProperty)

  const [isLoading, setIsLoading] = useState(false)

  const isEditing = useMemo(
    () => (!isEmpty(protocol?.id)),[protocol]
  )
  const defaultValues = useMemo(
    () => (
      {
        code: '',
        name: '',
        property: {
          id: currentProperty?.id
        },
        animal: {
          id: animalId
        },
        ...protocol
      }
    ), [animalId, currentProperty.id, protocol]
  )

  const closeModal = useCallback(
    () => {
      setIsLoading(false)
      handleClose()
    }, [handleClose]
  )

  const onSuccess = useCallback(
    () => {
      snackbar.success(
        t(`your {this} was ${  isEditing ? 'edited' :'registered' }`, {
          howMany: 1,
          gender: 'male',
          this: t('protocol')
        })
      )
      onSubmit()
      closeModal()

    }, [closeModal, isEditing, onSubmit, snackbar, t]
  )

  const handleSubmit = useCallback(
    (data) => {
      setIsLoading(true)
      dispatch(AnimalProtocolsActions.saveAnimalProtocols(
        {
          ...protocol,
          ...data
        },
        onSuccess,
        () => setIsLoading(false),
        protocol?.id,
        true
      ))
    },
    [dispatch, onSuccess, protocol]
  )

  return (
    <Modal
      id={ id }
      open={ open }
      title={
        <>
          <I18n params={ { this: t('protocol') } }>{'register {this}'}</I18n>
        </>
      }
      disableFullScreen
      disableEsc
      classes={ { paper: classes.modalBackground } }
      contentContainerStyle={ { minWidth: 300 } }
      headerProps={ {
        titleClass: classes.title,
        disableTypography: true
      } }
    >
      <>
        {
          isLoading ? <Loader width={ 100 } /> :
            <RegisterProtocolForm
              formRef={ formRef }
              defaultValues={ defaultValues }
              onSubmit={ handleSubmit }
              onCancel={ closeModal }
            />
        }
      </>
    </Modal>
  )}

RegisterProtocolModal.propTypes = {
  id: PropTypes.string.isRequired,
  animalId: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  onSubmit: PropTypes.func,
  protocol: PropTypes.object
}

RegisterProtocolModal.defaultProps = {
  handleClose: () => {},
  onSubmit: () => {},
  animalId: null,
  protocol: {}
}

export default RegisterProtocolModal
