import hexToRgba from 'hex-to-rgba'
import styled from 'styled-components'

import { colors } from '@smartcoop/styles'
import fonts from '@smartcoop/styles/fonts'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  box-shadow: 0px 0px 4.07652px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
  color: ${ colors.darkGrey };
  font-family: ${ fonts.fontFamilySans };
  background-color: ${ colors.white };
  width: 100%;
  flex: 1;
`

export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${ props => hexToRgba(props.backgroundColor, .15) };
  padding: 10px;
`
export const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  flex-wrap: wrap;
  flex: 1;
`

export const Line = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  width: 100%;
`

export const Big = styled.span`
  font-size: 44px;
`

export const Unit = styled.div`
  color: ${ colors.mediumGrey };
  margin-left: 5px;
`
export const Title = styled.span`
  font-family: ${ fonts.fontFamilyMontserrat };
  flex: 1;
`
