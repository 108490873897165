import { Model } from '@nozbe/watermelondb'
import { field } from '@nozbe/watermelondb/decorators'


export class AnimalModel extends Model {
  static table = 'animal'

  @field('animalId')
  animalId: string

  @field('inseminationDate')
  inseminationDate: string

  @field('nextActionDate')
  nextActionDate: string

  @field('birthDate')
  birthDate: string

  @field('lastBirth')
  lastBirth: string

  @field('slaughterDate')
  slaughterDate: string

  @field('expectedBirthDate')
  expectedBirthDate: string

  @field('calendarDate')
  calendarDate: string

  @field('diagnosisDate')
  diagnosisDate: string

  @field('carencyDate')
  carencyDate: string

  @field('observationDate')
  observationDate: string

  @field('propertyId')
  propertyId: string

  @field('earringCode')
  earringCode: string

  @field('nameAndCode')
  nameAndCode: string

  @field('category')
  category: string

  @field('lotId')
  lotId: string

  @field('lotName')
  lotName: string

  @field('lotSlug')
  lotSlug: string

  @field('breedId')
  breedId: number

  @field('breedName')
  breedName: string

  @field('statusId')
  statusId: number

  @field('statusName')
  statusName: string

  @field('statusSlug')
  statusSlug: string

  @field('inseminationId')
  inseminationId: string

  @field('nextAction')
  nextAction: string

  @field('actionName')
  actionName: string

  @field('color')
  color: string

  @field('observation')
  observation: string

  @field('lastObservation')
  lastObservation: string

  @field('daysUntil')
  daysUntil: number

  @field('nextActionOrderAlert')
  nextActionOrderAlert: number

  @field('nextActionAlert')
  nextActionAlert: string

  @field('pregnancyStatus')
  pregnancyStatus: string

  @field('inseminationActive')
  inseminationActive: boolean

  @field('aborted')
  aborted: boolean

  @field('servicesAfterLastBirth')
  servicesAfterLastBirth: string

  @field('services')
  services: string

  @field('daysLastInsemination')
  daysLastInsemination: number

  @field('daysLastAnimalBirth')
  daysLastAnimalBirth: number

  @field('del')
  del: number

  @field('ageInMonths')
  ageInMonths: number

  @field('conceptionRate')
  conceptionRate: string

  @field('abortionRate')
  abortionRate: string

  @field('motivation')
  motivation: string

  @field('motivationReason')
  motivationReason: string

  @field('active')
  active: boolean

  @field('deleted')
  deleted: boolean

}
