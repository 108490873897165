import React, {
  useState,
  useMemo,
  useEffect,
  useCallback
} from 'react'
import { useSelector, useDispatch } from 'react-redux'

import withObservables from '@nozbe/with-observables'
import moment from 'moment'
import PropTypes from 'prop-types'

import filter from 'lodash/filter'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import toNumber from 'lodash/toNumber'
import toString from 'lodash/toString'

import Grid from '@material-ui/core/Grid'

import { animalService } from '@smartcoop/database/services/animalService'
import { database } from '@smartcoop/database/web-database'
import registerInseminationSchema from '@smartcoop/forms/schemas/dairyFarm/registerInsemination.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { getBulls as getBullsService } from '@smartcoop/services/apis/smartcoopApi/resources/animalBulls'
import { AnimalActions } from '@smartcoop/stores/animal'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'
import { colors } from '@smartcoop/styles'
import { AnimalStatusCode, INSEMINATION_TYPES } from '@smartcoop/utils/constants'
import Button from '@smartcoop/web-components/Button'
import CheckboxButton from '@smartcoop/web-components/CheckboxGroup/CheckboxButton'
import Form from '@smartcoop/web-components/Form'
import InputDate from '@smartcoop/web-components/InputDate'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputText from '@smartcoop/web-components/InputText'
import InputUnit from '@smartcoop/web-components/InputUnit'

import {
  Container,
  ButtonsContainer,
  FormContainer,
  Item,
  Label,
  Row
} from './styles'

const RegisterInseminationForm = (props) => {
  const { defaultValues, onSubmit, onCancel, loading, animalsList, formRef } = props

  const dispatch = useDispatch()
  const [typeValue, setTypeValue] = useState([])
  const [options, setOptions] = useState([])
  const [animals, setAnimals] = useState([])
  const [sexing, setSexing] = useState(defaultValues?.sexing || false)

  const currentProperty = useSelector(selectCurrentProperty)

  useEffect(() => {
    dispatch(AnimalActions.loadAnimalInseminationTypes((response) => {
      setOptions(map(response, item => ({ label: item?.name, value: toString(item?.id) })))
    }))

    const loadAnimals = () => {
      const filteredAnimals = filter(animalsList, item => (item.category === 'vaca' ||
      item.category === 'novilha') &&
      (toString(item?.statusId) === AnimalStatusCode.VAZIA ||
        toString(item?.statusId) === AnimalStatusCode.APTAS ||
        toString(item?.statusId) === AnimalStatusCode.INSEMINADA_A_CONFIRMAR ||
        toString(item?.statusId) === AnimalStatusCode.INSEMINADA ||
        toString(item?.statusId) === AnimalStatusCode.PEV ||
        toString(item?.statusId) === AnimalStatusCode.EM_PROTOCOLO ||
        toString(item?.statusId) === AnimalStatusCode.NENHUM))

      setAnimals(map(filteredAnimals, item => ({ label: item?.earringCode, value: item.animalId })))
    }

    loadAnimals()
  },[animalsList, currentProperty.id, dispatch])

  const canShowSexing = useMemo(() =>
    typeValue === INSEMINATION_TYPES.IATF ||
    typeValue === INSEMINATION_TYPES['IA - CIO monitorado'] ||
    typeValue === INSEMINATION_TYPES['IA - CIO Visual'] ||
    typeValue === INSEMINATION_TYPES['IA - PGF']
  ,[typeValue])

  const t = useT()

  const handleSubmit = useCallback(
    (data) => {
      const type = find(options, item => item?.value === typeValue)
      onSubmit({
        ...data,
        sexing,
        inseminationType: [{ name: type?.label, id: type?.value }],
        bullId: !isEmpty(data.bullId) ? data.bullId : null,
        embryoBull: !isEmpty(data.embryoBull) ? data.embryoBull : null,
        embryoMother: !isEmpty(data.embryoMother) ? data.embryoMother : null,
        value: toNumber(data.inseminationValue),
        internal: typeValue === INSEMINATION_TYPES.Monta
      })
    },
    [onSubmit, options, sexing, typeValue]
  )

  const isMonta = useMemo(() => typeValue === INSEMINATION_TYPES.Monta, [typeValue])

  const isTypeTe = useMemo(() => typeValue === INSEMINATION_TYPES.TE, [typeValue])

  const handleSetTypeValue = useCallback((values) => {
    setTypeValue(values)
  }, [])

  const service = useMemo(
    () => (isMonta ? map(filter(animalsList, item => item.category === 'touro'), item => ({
      label: item?.nameAndCode,
      value: item?.animalId
    })) : getBullsService),
    [animalsList, isMonta]
  )

  const urlParams = useMemo(
    () => ({
      propertyId: currentProperty?.id
    }),
    [currentProperty]
  )

  const queryParams = useMemo(() => {
    if (isMonta) {
      return { category: 'touro' }
    }
    return {}
  }, [isMonta])

  const selectBull = useMemo(
    () => (
      <Row key={ isMonta ? 'propertyBull' : 'platformBulls' }>
        <InputSelect
          key={ isMonta ? 'propertyBull' : 'platformBulls' }
          name="bullId"
          label={ t('bull\'s name') }
          options={ service }
          urlParams={ urlParams }
          queryParams={ queryParams }
          asyncOptionLabelField="nameAndCode"
          defaultValue={ defaultValues.bullId || defaultValues.internalBullId }
        />
        {canShowSexing && (
          <CheckboxButton
            label={ t('sexing') }
            onChange={ () => setSexing(!sexing) }
            checked={ sexing }
            value={ sexing }
            hideColor
          />
        )}
      </Row>
    ),
    [canShowSexing, defaultValues.bullId, defaultValues.internalBullId, isMonta, queryParams, service, sexing, t, urlParams]
  )

  useEffect(() => {
    if (!isEmpty(defaultValues?.inseminationType)) {
      const typeFiltered = map(defaultValues.inseminationType, (i) => i.id)
      setTypeValue(toString(typeFiltered[0]))
    }
  }, [defaultValues])

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ registerInseminationSchema }
        schemaProps={ { isTypeTe } }
        onSubmit={ handleSubmit }
      >
        <FormContainer>
          <Grid container style={ { justifyContent: 'space-between' } }>
            <Item>
              <InputSelect
                label={ t('earrings') }
                name="animalsId"
                options={ animals }
                multiple
                defaultValue={ [defaultValues?.animal?.id] }
                disabled={ !!defaultValues?.id }
                checkBoxSelectAll
              />
            </Item>
            <Item>
              <InputDate
                label={ t('insemination date') }
                name="inseminationDate"
                fullWidth
                defaultValue={ defaultValues.inseminationDate }
                pickerProps={ {
                  maxDate: moment().format()
                } }
              />
            </Item>
            <Item>
              <InputSelect
                label={ t('type') }
                placeholder={ t('type') }
                name="inseminationType"
                options={ options }
                fullWidth
                defaultValue={ typeValue[0] }
                detached
                value={ typeValue[0] }
                onChange={ handleSetTypeValue }
              />
            </Item>
            <Item>
              {!isTypeTe && selectBull}
            </Item>
            {!isMonta && (
              <>
                <Item>
                  <InputText
                    label={ t('inseminator') }
                    name="inseminator"
                    placeholder={ t('inseminator') }
                    defaultValue={ defaultValues.inseminator }
                    fullWidth
                  />
                </Item>
                <Item>
                  <InputUnit
                    label={ t('insemination value') }
                    name="inseminationValue"
                    placeholder={ t('insemination value') }
                    defaultValue={ defaultValues?.cost?.value }
                    fullWidth
                  />
                </Item>
              </>
            )}
            {isTypeTe && (
              <>
                <I18n as={ Label }>embryo&apos;s data</I18n>
                <Item>
                  <InputText
                    label={ t('father') }
                    name="embryoBull"
                    fullWidth
                    placeholder={ t('father') }
                    defaultValue={ defaultValues.embryoBull }
                  />
                </Item>
                <Item>
                  <InputText
                    label={ t('mother') }
                    name="embryoMother"
                    fullWidth
                    placeholder={ t('mother') }
                    defaultValue={ defaultValues.embryoMother }
                  />
                </Item>
              </>
            )}
          </Grid>
        </FormContainer>
        <ButtonsContainer>
          <Button
            id="web-cancel-form-button"
            onClick={ onCancel }
            color={ colors.white }
            disabled={ loading }
            fullWidth
            style={ { marginRight: 5 } }
          >
            <I18n>cancel</I18n>
          </Button>
          <Button
            id="web-save-form-button"
            onClick={ () => formRef.current.submit() }
            disabled={ loading }
            fullWidth
            style={ { marginLeft: 5 } }
          >
            <I18n>save</I18n>
          </Button>
        </ButtonsContainer>
      </Form>
    </Container>
  )
}

RegisterInseminationForm.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  animalsList: PropTypes.array,
  defaultValues: PropTypes.object.isRequired,
  formRef: PropTypes.object.isRequired
}

RegisterInseminationForm.defaultProps = {
  loading: false,
  animalsList: [],
  onSubmit: () => {},
  onCancel: () => {}
}

const enhance = withObservables([], () => ({
  animalsList: animalService(database).observeAnimals()
}))

const EnhancedRegisterInseminationForm= enhance(RegisterInseminationForm)

export default EnhancedRegisterInseminationForm