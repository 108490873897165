import React, {
  useCallback
} from 'react'

import withObservables from '@nozbe/with-observables'
import moment from 'moment/moment'
import PropTypes from 'prop-types'

import isNaN from 'lodash/isNaN'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import toNumber from 'lodash/toNumber'

import Grid from '@material-ui/core/Grid'

import { animalService } from '@smartcoop/database/services/animalService'
import { database } from '@smartcoop/database/web-database'
import weightControlSchema from '@smartcoop/forms/schemas/dairyFarm/weightControl.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { colors } from '@smartcoop/styles'
import { momentBackDateTimeFormat, momentBackDateFormat } from '@smartcoop/utils/dates'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputDate from '@smartcoop/web-components/InputDate'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputUnit from '@smartcoop/web-components/InputUnit'

import {
  Container,
  ButtonsContainer,
  FormContainer,
  Item
} from './styles'

const RegisterWeightControlForm = (props) => {
  const { defaultValues, onSubmit, onCancel, loading, animalId, animals, formRef } = props

  const t = useT()

  const handleSubmit = useCallback(
    (data) => {
      onSubmit({
        ...defaultValues,
        ...data,
        weight: toNumber(data?.weight),
        height: toNumber(data?.height)
      })
    },
    [defaultValues, onSubmit]
  )

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ weightControlSchema }
        onSubmit={ handleSubmit }
      >
        <FormContainer>
          <Grid container style={ { justifyContent: 'space-between' } }>
            <Item>
              <InputSelect
                label={ t('earring') }
                name="animalId"
                options={ orderBy(map(animals, item => ({ label: item?.earringCode, value: item?.animalId, animal: item })), item => isNaN(toNumber(item?.label)) ? item?.label : toNumber(item?.label)) }
                defaultValue={ animalId }
                disabled={ animalId }
                order={ false }
              />
            </Item>
            <Item>
              <InputDate
                label={ t('date', { howMany: 1 }) }
                placeholder={ t('date', { howMany: 1 }) }
                name="date"
                fullWidth
                defaultValue={ defaultValues?.date ? moment(defaultValues?.date, momentBackDateTimeFormat).format(momentBackDateFormat) : '' }
              />
            </Item>
            <Item>
              <InputUnit
                label={ t('weight') }
                name="weight"
                type="float"
                unit="Kg"
                fullWidth
                defaultValue={ defaultValues?.weight }
                decimalScale={ 2 }
              />
            </Item>
            <Item>
              <InputUnit
                label={ t('height') }
                name="height"
                type="float"
                unit="cm"
                fullWidth
                defaultValue={ defaultValues?.height }
                decimalScale={ 2 }
              />
            </Item>
          </Grid>
        </FormContainer>
        <ButtonsContainer>
          <Button
            id="web-cancel-form-button"
            onClick={ onCancel }
            color={ colors.white }
            disabled={ loading }
            style={ { marginRight: 10 } }
          >
            <I18n>cancel</I18n>
          </Button>
          <Button
            id="web-save-form-button"
            onClick={ () => formRef.current.submit() }
            disabled={ loading }
            style={ { marginLeft: 10 } }
          >
            <I18n>save</I18n>
          </Button>
        </ButtonsContainer>
      </Form>
    </Container>
  )
}

RegisterWeightControlForm.propTypes = {
  animalId: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  animals: PropTypes.array,
  onSubmit: PropTypes.func,
  formRef: PropTypes.object.isRequired,
  onCancel: PropTypes.func,
  defaultValues: PropTypes.object.isRequired
}

RegisterWeightControlForm.defaultProps = {
  loading: false,
  animals: [],
  onSubmit: () => {},
  onCancel: () => {}
}

const enhance = withObservables([], () => ({
  animals: animalService(database).observeAnimals()
}))

const EnhancedRegisterWeightControlForm = enhance(RegisterWeightControlForm)

export default EnhancedRegisterWeightControlForm