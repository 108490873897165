import React, { useCallback, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'

import trimMask from '@meta-awesome/functions/src/trimMask'
import PropTypes from 'prop-types'

import  I18n, { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { FamilyGroupActions } from '@smartcoop/stores/familyGroup'
import Loader from '@smartcoop/web-components/Loader'
import Modal from '@smartcoop/web-components/Modal'
import RegisterFamilyGroupAccessForm from '@smartcoop/web-containers/forms/digitalProperty/profile/RegisterFamilyGroupAccessForm'

import useStyles from './styles'

const RegisterFamilyGroupAccessModal = (props) => {
  const {
    id,
    open,
    handleClose,
    userRequestItem,
    editPermissions,
    setRenderList
  } = props

  const formRef = useRef(null)
  const classes = useStyles()
  const snackbar = useSnackbar()
  const dispatch = useCallback(useDispatch(), [])
  const t = useT()

  const [isLoading, setIsLoading] = useState(false)
  const [userFound, setUserFound] = useState(null)
  const [propertiesToRegister, setPropertiesToRegister] = useState(userRequestItem?.userFamilyGroupProperties)

  const closeModal = useCallback(
    () => {
      setIsLoading(false)
      setRenderList(false)
      handleClose()
    }, [handleClose, setRenderList]
  )

  const submitForms = useCallback(
    (data) => {
      setIsLoading(true)
      if(userRequestItem?.id && !editPermissions) {
        dispatch(FamilyGroupActions.editFamilyGroupUser(
          {
            ...userRequestItem,
            ...data,
            district: data.neighborhood,
            cellPhone: trimMask(data.cellPhone),
            email: data.email,
            postalCode: trimMask(data.postalCode),
            document: trimMask(data.document)
          },
          (name) => {
            snackbar.success(
              t('user {this} edited', {
                this: name
              })
            )
            closeModal()
          },
          () => {closeModal()}
        ))
      } else if(userRequestItem?.id && editPermissions) {
        dispatch(FamilyGroupActions.editFamilyGroupUserAccess(
          {
            userId: userRequestItem?.id,
            properties: propertiesToRegister
          },
          (name) => {
            snackbar.success(
              t('user {this} edited', {
                this: name
              })
            )
            closeModal()
          },
          () => {closeModal()}
        ))
      } else {
        dispatch(FamilyGroupActions.createFamilyGroupAccess(
          {
            ...data,
            name: userFound ? null : data.name,
            district: data.neighborhood,
            familyGroupPermissions: propertiesToRegister,
            cellPhone: userFound ? null : trimMask(data.cellPhone),
            email: userFound ? null : data.email,
            postalCode: trimMask(data.postalCode),
            document: trimMask(data.document),
            moduleIds: [1]
          },
          (name) => {
            snackbar.success(
              t('user {this} created', {
                this: name
              })
            )
            closeModal()
          },
          () => {closeModal()}
        ))
      }
    }, [closeModal, dispatch, editPermissions, propertiesToRegister, snackbar, t, userFound, userRequestItem]
  )

  return (
    <Modal
      id={ id }
      open={ open }
      title={
        <>
          <I18n>family group</I18n>
        </>
      }
      disableFullScreen
      escape={ !isLoading }
      contentContainerStyle={ { padding: 0, minWidth: 300 } }
      headerProps={ {
        titleClass: classes.title,
        disableTypography: true
      } }
    >
      {
        isLoading ?
          <Loader width={ 100 } /> :
          (
            <>
              <RegisterFamilyGroupAccessForm
                ref={ formRef }
                onSubmit={ submitForms }
                onCancel={ handleClose }
                userFound={ userFound }
                setUserFound={ setUserFound }
                propertiesToRegister={ propertiesToRegister }
                setPropertiesToRegister={ setPropertiesToRegister }
                defaultValues={ userRequestItem }
                editPermissions={ editPermissions }
              />
            </>
          )
      }
    </Modal>
  )}

RegisterFamilyGroupAccessModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  userRequestItem: PropTypes.object,
  editPermissions: PropTypes.bool,
  setRenderList: PropTypes.func.isRequired
}

RegisterFamilyGroupAccessModal.defaultProps = {
  handleClose: () => {},
  userRequestItem: {},
  editPermissions: true
}

export default RegisterFamilyGroupAccessModal
