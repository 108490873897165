/* eslint-disable no-param-reassign */
import { Q } from '@nozbe/watermelondb'

import { getAnimals as getAnimalsService } from '@smartcoop/services/apis/smartcoopApi/resources/animal'

const ANIMAL_FIELDS = [
  'animalId', 'inseminationDate', 'nextActionDate', 'birthDate', 'lastBirth',
  'slaughterDate', 'expectedBirthDate', 'calendarDate', 'diagnosisDate',
  'carencyDate', 'observationDate', 'propertyId', 'earringCode',
  'nameAndCode', 'category', 'lotId', 'lotName', 'lotSlug', 'breedId',
  'breedName', 'statusId', 'statusName', 'statusSlug', 'inseminationId',
  'nextAction', 'actionName', 'color', 'observation', 'lastObservation',
  'daysUntil', 'nextActionOrderAlert', 'nextActionAlert', 'pregnancyStatus',
  'inseminationActive', 'aborted', 'servicesAfterLastBirth', 'services',
  'daysLastInsemination', 'daysLastAnimalBirth', 'del', 'ageInMonths',
  'conceptionRate', 'abortionRate', 'motivation', 'motivationReason',
  'active', 'deleted'
]

export function animalService(database) {
  async function createOrUpdate(animals = []) {
    await database.write(async () => {
      const promises = animals.map(animal => async () => {
        const register = await database.get('animal')
          .query(Q.where('animalId', animal.animalId))
          .fetch()

        if(animal?.deleted && register[0]) {
          await register[0].destroyPermanently()
          return
        }
        if (register[0]) {
          await register[0].update(item => {
            ANIMAL_FIELDS.forEach(field => {
              item[field] = animal[field]
            })
          })
          return
        }
        await database.get('animal').create(item => {
          ANIMAL_FIELDS.forEach(field => {
            item[field] = animal[field]
          })
        })
      })

      await Promise.all(promises.map(fn => fn()))
    })
  }

  async function deleteAnimalById(animalId) {
    await database.write(async () => {
      const register = await database.get('animal')
        .query(Q.where('animalId', animalId))
        .fetch()

      if (register[0]) {
        await register[0].markAsDeleted()
      }
    })
  }

  async function clearTable() {
    await database.write(async () => {
      await database.get('animal').query().destroyAllPermanently()
    })
  }

  async function findAnimalById(animalId) {
    return database
      .get('animal')
      .query(Q.where('animalId', animalId))
      .fetchOne()
  }

  function observeAnimals(animalId) {
    const animalCollection = database.collections.get('animal')

    return animalId
      ? animalCollection.query(Q.where('animalId', Q.eq(animalId))).observe()
      : animalCollection.query(Q.where('deleted', Q.notEq(true))).observe()
  }

  async function syncAnimals(params, propertyId) {
    const { data: { data: response } } = await getAnimalsService(
      { ...params, limit: 999999 },
      { propertyId }
    )

    await createOrUpdate(response)
  }

  return {
    createOrUpdate,
    clearTable,
    deleteAnimalById,
    findAnimalById,
    observeAnimals,
    syncAnimals
  }
}