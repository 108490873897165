import React, { useMemo, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'


import moment from 'moment/moment'
import PropTypes from 'prop-types'


import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import { animalService } from '@smartcoop/database/services/animalService'
import { database } from '@smartcoop/database/web-database'
import { useDialog } from '@smartcoop/dialog'
import { useT } from '@smartcoop/i18n'
import { plus, barnRounded, barnRoundedBlackFilling } from '@smartcoop/icons'
import { AnimalActions } from '@smartcoop/stores/animal'
import { selectHasFamilyGroupAndNotProperty } from '@smartcoop/stores/authentication/selectorAuthentication'
import { PropertyActions } from '@smartcoop/stores/property'
import { selectProperties, selectCurrentProperty, selectFamilyGroupProperties, selectFamilyGroupAccess } from '@smartcoop/stores/property/selectorProperty'
import { selectUserCanWrite } from '@smartcoop/stores/user/selectorUser'
import colors from '@smartcoop/styles/colors'
import { momentBackDateTimeFormat } from '@smartcoop/utils/dates'
import Icon from '@smartcoop/web-components/Icon'
import ItemSelection from '@smartcoop/web-components/ItemSelection'
import Modal from '@smartcoop/web-components/Modal'
import CreatePropertyModal from '@smartcoop/web-containers/modals/CreatePropertyModal'

import useStyles from './styles'

const ChangePropertyModal = ({ id, open, handleClose, onChooseProperty }) => {
  const classes = useStyles()
  const { createDialog, removeDialog } = useDialog()

  const dispatch = useCallback(useDispatch(), [])
  const t = useT()

  const properties = useSelector(selectProperties)
  const familyGroupProperties = useSelector(selectFamilyGroupProperties)
  const userWrite = useSelector(selectUserCanWrite)
  const hasFamilyGroupAndNotProperty = useSelector(selectHasFamilyGroupAndNotProperty)
  const familyGroupAccess = useSelector(selectFamilyGroupAccess)

  const currentProperty = useSelector(selectCurrentProperty)
  const beforeSelected = useMemo(
    () => currentProperty,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    if(familyGroupAccess) {
      dispatch(PropertyActions.loadProperties())
    }
  },[dispatch, familyGroupAccess])

  const options = useMemo(() => (
    map(properties, (item) => (
      {
        value: item.id,
        label: item.name
      }
    ))
  ), [properties])

  const familyGroupOptions = useMemo(() => (
    map(familyGroupProperties, (item) => (
      {
        value: item.id,
        label: item.name
      }
    ))
  ), [familyGroupProperties])

  const onAddNewArea = useCallback(
    () => {
      removeDialog({ id })
      createDialog({
        id: 'create-property-onboarding',
        Component: CreatePropertyModal
      })
    },
    [createDialog, id, removeDialog]
  )

  const onChange = useCallback(
    (org, isFamilyGroup) => {
      const property = find(isFamilyGroup ? familyGroupProperties : properties, (item) => (item.id === org.value))
      dispatch(PropertyActions.saveCurrentProperty(property))
      animalService(database).syncAnimals({}, property?.id)
      dispatch(AnimalActions.setListUpdatedAt(moment().subtract(4, 'minutes').format(momentBackDateTimeFormat)))
      onChooseProperty()
      handleClose()
    },
    [dispatch, familyGroupProperties, handleClose, onChooseProperty, properties]
  )

  const selectedProperty = useMemo(
    () => {
      if (isEmpty(beforeSelected)) return {}

      return {
        value: beforeSelected.id,
        label: beforeSelected.name
      }
    },
    [beforeSelected]
  )

  const footerButton = () => (
    // eslint-disable-next-line
    <div className={ classes.AddNewAreaContainer } onClick={ () => {onAddNewArea()} }>
      <Icon icon={ plus } size={ 16 }/>
      {t('register new property')}
    </div>
  )



  return (
    <Modal
      id={ id }
      open={ open }
      title={ t('select one property') }
      maxWidth="xs"
      disableFullScreen
      escapeWhenLoading
      escape={ !isEmpty(beforeSelected) }
      classes={ { paper: classes.modalBackground } }
      contentContainerStyle={ {
        padding: '15px 0 0 0'
      } }
      headerProps={ {
        titleClass: classes.title
      } }
      FooterComponent={ userWrite && !hasFamilyGroupAndNotProperty ? footerButton : undefined }
    >
      <ItemSelection
        title={ !isEmpty(beforeSelected) && !hasFamilyGroupAndNotProperty ? t('other properties') : '' }
        options={ options }
        unselectedIcon={ barnRounded }
        unselectedIconColor={ colors.primary }
        selectedIcon={ barnRoundedBlackFilling }
        selectedIconColor={ colors.secondary }
        onChange={ org => onChange(org, false) }
        selected={ selectedProperty }
      />

      {!isEmpty(familyGroupProperties) && (
        <ItemSelection
          title={ t('family group properties') }
          options={ familyGroupOptions }
          unselectedIcon={ barnRounded }
          unselectedIconColor={ colors.primary }
          selectedIcon={ barnRoundedBlackFilling }
          selectedIconColor={ colors.secondary }
          onChange={ org => onChange(org, true) }
          selected={ selectedProperty }
          showSelected={ false }
        />
      )}
    </Modal>
  )}

ChangePropertyModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onChooseProperty: PropTypes.func.isRequired
}

export default ChangePropertyModal
