import React, { useMemo, useCallback, useEffect } from 'react'
import { useSelector , useDispatch } from 'react-redux'

import Grid from '@material-ui/core/Grid'

import I18n from '@smartcoop/i18n'
import useSmartcoopApi from '@smartcoop/services/hooks/useSmartcoopApi'
import { DairyFarmActions } from '@smartcoop/stores/dairyFarm'
import { selectCurrentSection } from '@smartcoop/stores/dairyFarm/selectorDairyFarm'
import { colors } from '@smartcoop/styles'
import Button from '@smartcoop/web-components/Button'
import DairyFarmReportFragment from '@smartcoop/web-containers/fragments/reports/dairyFarm/DairyFarmReportFragment/DairyFarmReportFragment'

import CattleManagement from './CattleManagement'
import DairyFarmDashboard from './DairyFarmDashboard/DairyFarmDashboard'
import {
  Container,
  Title,
  Top,
  ActionsContainer,
  Half
} from './styles'

const DairyFarmDashboardScreen = () => {

  const currentSection = useSelector(selectCurrentSection)
  const dispatch = useCallback(useDispatch(), [])
  const { data: token, isValidating } = useSmartcoopApi('/v1/auth/microsoft-token')

  const accessToken = useMemo(
    () => token?.accessToken,
    [token]
  )
  const renderType = useMemo(
    () => ({
      dairyFarm: <DairyFarmDashboard/>,
      cattleManagement: <CattleManagement />,
      dashboard: <DairyFarmReportFragment accessToken={ accessToken } isValidating={ isValidating }/>

    }[currentSection]), [accessToken, currentSection, isValidating]
  )

  useEffect(() => () => {
    dispatch(DairyFarmActions.resetCurrentSection())
  }, [dispatch])

  return (
    <Container>
      <>
        <Top>
          <Title>
            <I18n>dairy farm</I18n>
          </Title>
          <ActionsContainer>
            <Grid container>
              <Grid item xs={ 12 } sm={ 12 } md={ 12 }>
                <Half>
                  <div>
                    <Button
                      id="button"
                      color={ currentSection === 'dairyFarm' ? colors.primary : 'white' }
                      style={ { marginRight: 10 } }
                      onClick={ () => dispatch(DairyFarmActions.setCurrentSection('dairyFarm')) }
                    >
                      <I18n>my dairy farm</I18n>
                    </Button>
                  </div>
                  <div>
                    <Button
                      id="button"
                      style={ { marginRight: 10 } }
                      color={ currentSection === 'cattleManagement' ? 'primary' : 'white' }
                      onClick={ () => dispatch(DairyFarmActions.setCurrentSection('cattleManagement')) }
                    >
                      <I18n>cattle management</I18n>
                    </Button>
                  </div>
                  <div>
                    <Button
                      id="button"
                      style={ { marginRight: 10 } }
                      color={ currentSection === 'dashboard' ? 'primary' : 'white' }
                      onClick={ () => dispatch(DairyFarmActions.setCurrentSection('dashboard')) }
                    >
                      <I18n>dashboard</I18n>
                    </Button>
                  </div>
                </Half>
              </Grid>
            </Grid>
          </ActionsContainer>
        </Top>
        <div style={ currentSection === 'dashboard' ? { display: 'flex', flex: 1 } : {} }>
          {renderType}
        </div>
      </>
    </Container>
  )
}

export default DairyFarmDashboardScreen
