import React, { useCallback, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import  I18n, { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { AnimalActions } from '@smartcoop/stores/animal'
import Loader from '@smartcoop/web-components/Loader'
import Modal from '@smartcoop/web-components/Modal'
import ChangeLotsForm from '@smartcoop/web-containers/forms/digitalProperty/dairyFarm/ChangeLotsForm'

import useStyles from './styles'

const ChangeLotsModal = (props) => {
  const {
    id,
    open,
    onSubmit,
    handleClose
  } = props

  const formRef = useRef(null)
  const classes = useStyles()
  const snackbar = useSnackbar()
  const dispatch = useCallback(useDispatch(), [])
  const t = useT()

  const [isLoading, setIsLoading] = useState(false)

  const closeModal = useCallback(
    () => {
      setIsLoading(false)
      handleClose()
    }, [handleClose]
  )

  const onSuccess = useCallback(
    () => {
      snackbar.success(
        t('your lots were updated')
      )
      onSubmit()
      closeModal()

    }, [closeModal, onSubmit, snackbar, t]
  )

  const handleSubmit = useCallback(
    (data) => {
      setIsLoading(true)
      dispatch(AnimalActions.changeLots(
        {
          ...data
        },
        onSuccess,
        () => setIsLoading(false)
      ))
    },
    [dispatch, onSuccess]
  )

  return (
    <Modal
      id={ id }
      open={ open }
      title={
        <>
          <I18n>change lots</I18n>
        </>
      }
      disableFullScreen
      disableEsc
      classes={ { paper: classes.modalBackground } }
      headerProps={ {
        titleClass: classes.title,
        disableTypography: true
      } }
      maxWidth="md"
      fullWidth
    >
      <>
        {
          isLoading ? <Loader width={ 100 } /> :
            <ChangeLotsForm
              formRef={ formRef }
              onSubmit={ handleSubmit }
              onCancel={ closeModal }
              loading={ isLoading }
            />
        }
      </>
    </Modal>
  )}

ChangeLotsModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  onSubmit: PropTypes.func
}

ChangeLotsModal.defaultProps = {
  handleClose: () => {},
  onSubmit: () => {}
}

export default ChangeLotsModal
