import React, { useMemo, useEffect, useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { isEmpty } from 'lodash'

import Grid from '@material-ui/core/Grid'

import I18n from '@smartcoop/i18n'
import { AnimalActions } from '@smartcoop/stores/animal'
import {
  selectActiveTab,
  selectCurrentAnimal
} from '@smartcoop/stores/animal/selectorAnimal'
import { DairyFarmActions } from '@smartcoop/stores/dairyFarm'
import { LotActions } from '@smartcoop/stores/lot'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'
import { colors } from '@smartcoop/styles'
import Button from '@smartcoop/web-components/Button'
import GoBackIconButton from '@smartcoop/web-components/IconButton/GoBackIconButton'
import RegisterAnimalForm from '@smartcoop/web-containers/forms/digitalProperty/dairyFarm/RegisterAnimalForm'
import DiagnosisListFragment from '@smartcoop/web-containers/fragments/digitalProperty/dairyFarm/DiagnosisListFragment'
import DryingListFragment from '@smartcoop/web-containers/fragments/digitalProperty/dairyFarm/DryingListFragment'
import ListMedicationControlFragment from '@smartcoop/web-containers/fragments/digitalProperty/dairyFarm/ListMedicationControlFragment'
import ListObservations from '@smartcoop/web-containers/fragments/digitalProperty/dairyFarm/ListObservations'
import ListPevFragment from '@smartcoop/web-containers/fragments/digitalProperty/dairyFarm/ListPevFragment'
import { useRoutes } from '@smartcoop/web-containers/routes/authenticated'

import ListAnimalBirth from '../ListAnimalBirth'
import ListInsemination from '../ListInsemination'
import ListProtocol from '../ListProtocol'
import ListWeightControl from '../ListWeightControl/ListWeightControl'
import { Container, Title, Top, ActionsContainer, Half } from './styles'

const RegisterAnimalsScreen = () => {
  const activeTab = useSelector(selectActiveTab)
  const dispatch = useCallback(useDispatch(), [])
  const history = useHistory()
  const { routes } = useRoutes()
  const currentProperty = useSelector(selectCurrentProperty)
  const currentAnimal = useSelector(selectCurrentAnimal)
  const [currentStatus, setCurrentStatus] = useState(currentAnimal?.statusId || '')
  const [loading, setLoading] = useState(false)


  const dairyFarm = useMemo(() => currentProperty?.dairyFarm[0], [currentProperty.dairyFarm])

  const disabledButton = useMemo(
    () => isEmpty(currentAnimal) || currentAnimal.isNewAnimal || loading,
    [currentAnimal, loading]
  )

  useEffect(() => {
    if(!isEmpty(currentAnimal?.animalId)) {
      setLoading(true)
      dispatch(AnimalActions.loadCurrentAnimal(() => setLoading(false), () => setLoading(false)))
    }
  },[currentAnimal, dispatch])

  const renderType = useMemo(
    () =>
      ({
        registerAnimal: <RegisterAnimalForm loading={ loading } setCurrentStatus={ setCurrentStatus } currentStatus={ currentStatus }/>,
        diagnosis: <DiagnosisListFragment />,
        weightControl: <ListWeightControl />,
        insemination: <ListInsemination />,
        protocol: <ListProtocol />,
        drying: <DryingListFragment />,
        calving: <ListAnimalBirth />,
        pev: <ListPevFragment />,
        medicationControl: <ListMedicationControlFragment />,
        observation: <ListObservations />
      }[activeTab]),
    [activeTab, currentStatus, loading]
  )

  const handleClickBack = useCallback(() => {
    history.push(routes.dairyFarmDashboard.path)
    dispatch(DairyFarmActions.setCurrentSection('cattleManagement'))
  }, [dispatch, history, routes])


  useEffect(
    () => () => {
      dispatch(AnimalActions.resetCurrentAnimal())
      dispatch(AnimalActions.resetIsDetail())
      dispatch(AnimalActions.setActiveTab('registerAnimal'))
      dispatch(LotActions.loadLots({ propertyId: currentProperty?.id }))
    },
    [currentProperty, dispatch]
  )

  return (
    <Container>
      {!isEmpty(dairyFarm) && (
        <>
          <Top>
            <div style={ { display: 'flex', alignItems: 'center' } }>
              <GoBackIconButton
                onClick={ handleClickBack }
                iconColor={ colors.primary }
              />
              <Title>
                <I18n>earring</I18n>  {currentAnimal?.earring?.earringCode ? `${ currentAnimal?.earring?.earringCode } - ${ currentAnimal?.name }` :  currentAnimal?.earringCode}
              </Title>
            </div>
            <ActionsContainer>
              <Grid container>
                <Grid item xs={ 12 } sm={ 12 } md={ 12 }>
                  <Half style={ { marginBottom: 10 } }>
                    <div>
                      <Button
                        id="register-animal"
                        color={
                          activeTab === 'registerAnimal'
                            ? colors.primary
                            : 'white'
                        }
                        style={ { marginRight: 10, minWidth: '15vw' } }
                        onClick={ () =>
                          dispatch(AnimalActions.setActiveTab('registerAnimal'))
                        }
                        disabled={ loading }
                      >
                        <I18n params={ { howMany: 1 } }>register animal</I18n>
                      </Button>
                    </div>
                    <div>
                      <Button
                        id="button"
                        style={ { marginRight: 10, minWidth: '15vw' } }
                        color={
                          activeTab === 'protocol' ? 'primary' : 'white'
                        }
                        onClick={ () =>
                          dispatch(AnimalActions.setActiveTab('protocol'))
                        }
                        disabled={ disabledButton }
                      >
                        <I18n>protocol</I18n>
                      </Button>
                    </div>
                    <div>
                      <Button
                        id="button"
                        style={ { marginRight: 10, minWidth: '15vw' } }
                        color={
                          activeTab === 'insemination' ? 'primary' : 'white'
                        }
                        onClick={ () =>
                          dispatch(AnimalActions.setActiveTab('insemination'))
                        }
                        disabled={ disabledButton }
                      >
                        <I18n>insemination</I18n>
                      </Button>
                    </div>
                    <div>
                      <Button
                        id="button"
                        style={ { marginRight: 10, minWidth: '15vw' } }
                        color={ activeTab === 'diagnosis' ? 'primary' : 'white' }
                        onClick={ () =>
                          dispatch(AnimalActions.setActiveTab('diagnosis'))
                        }
                        disabled={ disabledButton }
                      >
                        <I18n params={ { howMany: 1 } }>diagnosis</I18n>
                      </Button>
                    </div>
                    <div>
                      <Button
                        id="button"
                        style={ { minWidth: '15vw' } }
                        color={ activeTab === 'drying' ? 'primary' : 'white' }
                        onClick={ () =>
                          dispatch(AnimalActions.setActiveTab('drying'))
                        }
                        disabled={ disabledButton }
                      >
                        <I18n>other actions</I18n>
                      </Button>
                    </div>
                  </Half>
                  <Half>
                    <div>
                      <Button
                        id="button"
                        style={ { marginRight: 10, minWidth: '15vw' } }
                        color={ activeTab === 'calving' ? 'primary' : 'white' }
                        onClick={ () =>
                          dispatch(AnimalActions.setActiveTab('calving'))
                        }
                        disabled={ disabledButton }
                      >
                        <I18n>calving</I18n>
                      </Button>
                    </div>
                    <div>
                      <Button
                        id="button"
                        style={ { marginRight: 10, minWidth: '15vw' } }
                        color={ activeTab === 'pev' ? 'primary' : 'white' }
                        onClick={ () =>
                          dispatch(AnimalActions.setActiveTab('pev'))
                        }
                        disabled={ disabledButton }
                      >
                        <I18n>pev</I18n>
                      </Button>
                    </div>
                    <div>
                      <Button
                        id="button"
                        style={ { marginRight: 10, minWidth: '15vw' } }
                        color={ activeTab === 'medicationControl' ? 'primary' : 'white' }
                        onClick={ () =>
                          dispatch(AnimalActions.setActiveTab('medicationControl'))
                        }
                        disabled={ isEmpty(currentAnimal) || loading }
                      >
                        <I18n>vaccines and medications</I18n>
                      </Button>
                    </div>
                    <div>
                      <Button
                        id="button"
                        style={ { marginRight: 10, minWidth: '15vw' } }
                        color={ activeTab === 'observation' ? 'primary' : 'white' }
                        onClick={ () =>
                          dispatch(AnimalActions.setActiveTab('observation'))
                        }
                        disabled={ isEmpty(currentAnimal) || loading }
                      >
                        <I18n params={ { howMany: 1 } }>observation</I18n>
                      </Button>
                    </div>
                    <div>
                      <Button
                        id="button"
                        style={ { minWidth: '15vw' } }
                        color={
                          activeTab === 'weightControl' ? 'primary' : 'white'
                        }
                        onClick={ () =>
                          dispatch(AnimalActions.setActiveTab('weightControl'))
                        }
                        disabled={ disabledButton }
                      >
                        <I18n>weight gain</I18n>
                      </Button>
                    </div>
                  </Half>
                </Grid>
              </Grid>
            </ActionsContainer>
          </Top>
          {renderType}
        </>
      )}
    </Container>
  )
}

export default RegisterAnimalsScreen
